:root {
  --nav-bar-dark: #B86500;
  --nav-bar-dark-sub: #DD55;
  --background-body: #E5E5E5;
  --background-content: #1D1D1D;
  --text-color: #292929;
  --primary-color: #4DFF7C;
  --second-color: #89DDFF;
  --header-background: #04AA6D;
  --header-color: #FFFFFF;
  --row-background: #E5E5E5;
  --row-color: #000000;
  --button-color: #098356;
  --button-text-color: #FFFFFF;
  --green-color: #04AA6D;
  --red-color: #FF0000;
  --dark: #292929;
  --off-white: #E5E5E5;
  --nav-bar-orange-red:  #FF5733;
  --nav-bar-cadmium-red:  #D22B2B;
  --image-upload-button-color: #098356;
}

/* body {
  margin: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-body);
  color: var(--text-color);
}

header {
  position: fixed;
  width: 100%;
  height: 3.5rem;
  top: 0px;
  left: 0px;
  background-color: var(--background-content);
  color: var(--background-body);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.header__content {
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 3.5rem;
}

.logo {
  font-size: 20px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--background-body);
} */

main {
  width: 100%;
  margin-bottom: 3rem;
  /* background-color: var(--background-content); */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  border-radius: 3px;
}

.add-user-form {
  width: fit-content;
  margin: auto;
}

/* input, select {
  border: none;
  border-radius: 3px;
  padding: 6px;
  margin:  0 6px;
} */

select {
  border: none;
  border-radius: 3px;
  padding: 6px;
  margin:  0 6px;
}

h3 {
  text-align: center;
  margin-top: 0;
}

.button {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 0.80rem;
  display: block;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 0.1rem;
}

.btn-add-user-submit {
  background-color: var(--second-color);
  padding: 6px 12px;
  cursor: pointer;
  font-weight: bolder;
  margin-left: 2px;
}

button:hover,
.btn-submit:hover {
  opacity: 0.8;
}

/* .all-users {
  width: 100%;
  float: right;
  border-collapse: collapse;
} */

.table-div-parent {
  width: 100%;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  /* padding-bottom: 5px; */
  /* background-color: var(--off-white); */
}

.table-row-content-padding {
  padding-top: 10px;
}

.titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  /* background-color: var(--nav-bar-cadmium-red); */
  color: var(--off-white);
  margin-Top: 60px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.row {
  width: 100%;
  height: 50px;
  display: flex;
  text-align: left;
  margin-left: 0px;
  background-color: var(--off-white);
  color: var(--dark);
}

.row:nth-child(even){background-color: white;}

.row:hover {background-color: #d3f2fd;}

.titles div,
.row input,
.row select,
  .row div{
  width: 14.28%;
  padding: 0.2rem;
  text-align: center;
}

.hide-form {
  display: none;
}

.show-form {
  display: block;
}

.report-titles {
  width: 85%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--off-white);
  color: var(--nav-bar-dark);
  margin-Top: 60px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.report-row {
  width: 85%;
  height: 50px;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--dark);
  color: var(--off-white);
  justify-content: center;
}

.report-row:nth-child(odd){background-color: black;}
.report-row:nth-child(even){background-color: black;}

.report-row:hover {background-color: var(--dark);}

.report-titles div,
.report-row input,
.report-row select,
.report-row div{
  width: 20%;
  padding: 0.2rem;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.report-hide-form {
  display: none;
}

.report-show-form {
  display: block;
}

@media screen and (max-width: 920px) {
  main {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  main {
    width: 100%;
  }
}

.black_overlay {
  display: none;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: .80;
  filter: alpha(opacity=80);
}
.white_content {
  display: none;
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  padding: 16px;
  border: 16px solid orange;
  background-color: white;
  z-index: 1002;
  overflow: auto;
}

 /* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 35%;
  margin: 0 auto;
  height: 100%;
  max-height: auto;
  margin-top: calc(100vh - 85vh - 20px);
  background: #00000090;
  border-radius: 4px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(33% - 18px);
  top: calc(100vh - 85vh - 25px);
  background: var(--second-color);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.container-test {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px;
}
.box-test {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: #0057e3;
}
.overlay-test {
  z-index: 9;
  margin: 30px;
  background: #009938;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.btn-group button {
  background-color: #04AA6D; /* Green background */
  border: 1px solid green; /* Green border */
  color: white; /* White text */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
  margin: 2px;
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #3e8e41;
}

.calendar {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 30px;
  justify-content: center;
  /* background-color: var(--row-background);
  color: var(--row-color); */
}

.get-my-users-button {
  background-color: var(--green-color);
  cursor: pointer;
  color: var(--button-text-color);
  font-size:14px;
}

.label-report {
  display: block;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  font-family: Arial;
  font-size: 15px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(102, 101, 101, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.success {background-color: #04AA6D;} /* Green */
.info {background-color: #2196F3;} /* Blue */
.warning {background-color: #ff9800;} /* Orange */
.danger {background-color: #f44336;} /* Red */ 
.other {background-color: #e7e7e7; color: black;} /* Gray */ 
.black {background-color: var(--nav-bar-dark); color: var(--off-white);} /* Dark */ 

.win-choice {
  display: block;
  color: white;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-family: Arial;
  font-size: 15px;
}

.choice-default {background-color: #04AA6D; width: 50%} /* Green */
.choice-best-2 {background-color: #2196F3;} /* Blue */
.choice-best-3 {background-color: #ff9800;} /* Orange */
.choice-best-4 {background-color: #f44336;} /* Red */ 
.choice-best-5 {background-color: #e7e7e7; color: black;} /* Gray */ 
.choice-best-6 {background-color: var(--dark); color: var(--off-white);} /* Dark */

.unsettled-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 30px;
  justify-content: center;
}

.unsettled-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 60px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  margin-Top: 60px;
}

.unsettled-row {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: var(--dark);
  color: var(--off-white);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
}

.unsettled-row:nth-child(even){background-color: black;}

.unsettled-row:hover {background-color: var(--nav-bar-dark);}

.unsettled-titles div,
.unsettled-row input,
.unsettled-row select,
  .unsettled-row div{
  width: 25%;
  padding: 0.2rem;
  text-align: center;
}

[data-pagination],
[data-pagination] *,
[data-pagination] *:before,
[data-pagination] *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}
[data-pagination] {
  font-size: 8pt;
  line-height: 1;
  font-weight: 400;
  font-family: 'Open Sans', 'Source Sans Pro', Roboto, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', 'Myriad Pro', 'Segoe UI', Myriad, Helvetica, 'Lucida Grande', 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  margin: 1em auto;
  text-align: center;
  transition: font-size .2s ease-in-out;
}
[data-pagination] ul {
  list-style-type: none;
  display: inline;
  font-size: 100%;
  margin: 0;
  padding: .5em;
}
[data-pagination] ul li {
  display: inline-block;
  font-size: 100%;
  width: auto;
  border-radius: 3px;
}
[data-pagination] > a {
  font-size: 140%;
}
[data-pagination] a {
  color: #777;
  font-size: 100%;
  padding: .5em;
}
[data-pagination] a:focus,
[data-pagination] a:hover {
  color: #f60;
}
[data-pagination] li.current {
  background: rgba(0,0,0,.1)
}
/* Disabled & Hidden Styles */
[data-pagination] .disabled,
[data-pagination] [hidden],
[data-pagination] [disabled] {
  opacity: .5;
  pointer-events: none;
}
@media (min-width: 350px) {
  [data-pagination] {
    font-size: 10pt;
  }
}
@media (min-width: 500px) {
  [data-pagination] {
    font-size: 12pt;
  }
}
@media (min-width: 700px) {
  [data-pagination] {
    font-size: 14pt;
  }
}
@media (min-width: 900px) {
  [data-pagination] {
    font-size: 16pt;
  }
}

.myupi-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.myupi-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.myupi-row {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
}

.myupi-row:nth-child(odd){background-color: var(--off-white);}
.myupi-row:nth-child(even){background-color: white;}

.myupi-row:hover {background-color: #D5D6EA;}

.myupi-titles div,
.myupi-row input,
.myupi-row select,
.myupi-row div{
  width: 25%;
  padding: 0.2rem;
  text-align: center;
}

.card-view-header {
  width: fit-content;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 8px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  margin-left: auto;
  margin-right: auto;
}

.card-view-card {
  width: fit-content;
  text-align: left;
  font-weight: bold;
  background-color: white;
  margin-Top: 30px;
  margin-bottom: 20px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px 8px 8px 8px;
}

.withdraw-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.withdraw-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.withdraw-row {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
}

.withdraw-row:nth-child(odd){background-color: var(--off-white);}
.withdraw-row:nth-child(even){background-color: white;}

.withdraw-row:hover {background-color: #d3f2fd;}

.withdraw-titles div,
.withdraw-row input,
.withdraw-row select,
.withdraw-row div{
  width: 11%;
  padding: 0.2rem;
  text-align: center;
}

.myusers-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.myusers-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.myusers-row {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
}

.myusers-row:nth-child(odd){background-color: var(--off-white);}
.myusers-row:nth-child(even){background-color: white;}

.myusers-row:hover {background-color: #D5D6EA;}

.myusers-titles div,
.myusers-row input,
.myusers-row select,
.myusers-row div{
  width: 16%;
  padding: 0.2rem;
  text-align: center;
}

.card-view-header {
  width: fit-content;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 8px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  margin-left: auto;
  margin-right: auto;
}

.recharge-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.recharge-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--nav-bar-dark);
  color: var(--off-white);
  margin-Top: 30px;
  margin-bottom: 10px;
  padding: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
}

.recharge-row {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
}

.recharge-row:nth-child(odd){background-color: var(--off-white);}
.recharge-row:nth-child(even){background-color: white;}

.recharge-row:hover {background-color: #D5D6EA;}

.recharge-titles div,
.recharge-row input,
.recharge-row select,
.recharge-row div{
  width: 15%;
  padding: 0.2rem;
  text-align: center;
}

/* Pagination Page Number & Items Count Span */

.label-depth-shadow {
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3), inset 5px 5px 8px rgba(0,0,0,0.3);
  display: block;
  /* color: white;
  padding-left: 6px;
  padding-right: 6px;
  font-family: Arial;
  font-size: 15px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(102, 101, 101, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px; */
}

.prev-img {
  border-radius: 20px;
  width: 40px;
}

.next-img {
  border-radius: 20px;
  width: 40px;
}

.play-img {
  border-radius: 20px;
  width: 24px;
  height: 24px;
}

.trans-history-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.trans-history-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--green-color);
  color: var(--off-white);
  border: 2px solid var(--green-color);
  border-bottom: none;
  /* -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px; */
}

.trans-container {
  width: 100%;
  overflow-y: auto;
  border: 2px solid var(--green-color);
}

.trans-history-row {
  width: 100%;
  height: 30px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
  font-size: 15px;
}

.trans-history-row:nth-child(odd){background-color: var(--off-white);}
.trans-history-row:nth-child(even){background-color: white;}

.trans-history-row:hover {background-color: #D5D6EA;}

.trans-history-titles div,
.trans-history-row input,
.trans-history-row select,
.trans-history-row div {
  width: 20%;
  padding: 0.2rem;
  text-align: center;
}

.bet-history-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.bet-history-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--green-color);
  color: var(--off-white);
  border: 2px solid var(--green-color);
  border-bottom: none;
  /* -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px; */
}

.bets-container {
  width: 100%;
  overflow-y: auto;
  border: 2px solid var(--green-color);
}

.bet-history-row {
  width: 100%;
  height: 30px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
  font-size: 15px;
}

.bet-history-row:nth-child(odd){background-color: var(--off-white);}
.bet-history-row:nth-child(even){background-color: white;}

.bet-history-row:hover {background-color: #D5D6EA;}

.bet-history-titles div,
.bet-history-row input,
.bet-history-row select,
.bet-history-row div {
  width: 20%;
  padding: 0.2rem;
  text-align: center;
}

.tree-level-container {
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  justify-content: center;
}

.tree-level-titles {
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  background-color: var(--green-color);
  color: var(--off-white);
  border: 2px solid var(--green-color);
  border-bottom: none;
  /* -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px; */
}

.levels-container {
  width: 100%;
  overflow-y: auto;
  border: 2px solid var(--green-color);
}

.tree-level-row {
  width: 100%;
  height: 30px;
  display: flex;
  background-color: var(--off-white);
  color: var(--dark);
  text-align: center;
  margin-left: auto;
  margin-right:auto;
  font-size: 15px;
}

.tree-level-row:nth-child(odd){background-color: var(--off-white);}
.tree-level-row:nth-child(even){background-color: white;}

.tree-level-row:hover {background-color: #D5D6EA;}

.tree-level-titles div,
.tree-level-row input,
.tree-level-row select,
.tree-level-row div {
  width: 33.33%;
  padding: 0.2rem;
  text-align: center;
}

.user-detail-tabs-right-pane {
  width: 100%;
  border-radius: 2px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3), inset 5px 5px 8px rgba(0,0,0,0.3);
  display: block;
}