.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.top-div {
  margin: 2%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 20px 20px;
}

.card {
  background-color: #f7f7f7;
  padding: 10px 15px 20px;
  margin: 0 auto 25px;
  margin-top: 30px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.game-board-card-container {
  display: flex;
}

.card-game-board-default {
  position: relative;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  /* background-color: var(--green-color); */
  max-width: 240px !important;
  height: 180px;
  padding: 40px 20px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.card-game-board-default:hover,
.card-game-board-default-submit:hover {
  opacity: 0.8;
}

.card-game-board-default:after {
  content: "";
  background: #FFFFFF;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.card-game-board-default:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.choice-default {background-color: #04AA6D; width: 240px; margin-right: 5px;} /* Green */
.choice-best-2 {background-color: #2196F3; width: 210px; margin-right: 5px;} /* Blue */
.choice-best-3 {background-color: #ff9800; width: 180px; margin-right: 5px;} /* Orange */
.choice-best-4 {background-color: #f44336; width: 150px; margin-right: 5px;} /* Red */ 
.choice-best-5 {background-color: #f44336; width: 120px; margin-right: 5px;} /* Yellow */
.choice-best-6 {background-color: #964B00; width: 110px; margin-right: 5px;} /* Brown */
.choice-best-7 {background-color: #FF69B4; width: 100px; margin-right: 5px;} /* Pink */ 
.choice-best-8 {background-color: #A020F0; width: 90px; margin-right: 5px;} /* Purple */ 
.choice-best-9 {background-color: #50C878; width: 80px; margin-right: 5px;} /* Emrald */ 
.choice-best-0 {background-color: var(--dark); color: var(--off-white); width: 70px; margin-right: 5px;} /* Dark */

.board-buttons-container {
  display: flex;
}

.board-buttons {
  position: relative;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  width: 155px;
  height: 40px;
  /* background-color: var(--green-color); */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.board-buttons:hover,
.board-buttons-submit:hover {
  opacity: 0.8;
}

.board-buttons:after {
  content: "";
  background: #FFFFFF;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.board-buttons:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.board-buttons:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.board-buttons-nav-bar-dark {background-color: var(--nav-bar-dark); margin-right: 5px;} /* Nav Dark; */
.board-buttons-nav-bar-dark-small {background-color: var(--nav-bar-dark); width: 120px; margin-right: 5px;} /* Nav Dark */
.board-buttons-nav-bar-dark-smaller {background-color: var(--nav-bar-dark); width: 80px; margin-right: 5px;} /* Nav Dark */
.board-buttons-nav-bar-dark-smallest {background-color: var(--nav-bar-dark); width: 60px; margin-right: 5px;} /* Nav Dark */
.board-buttons-nav-bar-dark-tiny {background-color: var(--nav-bar-dark); width: 30px; height: 35px; margin-right: 1px} /* Nav Dark */
.board-buttons-nav-bar-dark-tiny-trans {background-color: transparent; width: 34px; height: 35px; margin-right: 0px} /* Nav Dark */
.board-buttons-nav-bar-dark-red {background-color: red; width: 155px; margin-right: 5px;} /* Red */ 

.board-buttons-green-large {background-color: #04AA6D; width: 200px; margin-right: 5px;} /* Green */
.board-buttons-green {background-color: #04AA6D; margin-right: 5px;} /* Green */
.board-buttons-green-small {background-color: #04AA6D; width: 120px; margin-right: 5px;} /* Green */
.board-buttons-green-smaller {background-color: #04AA6D; width: 80px; margin-right: 5px;} /* Green */
.board-buttons-green-smallest {background-color: #04AA6D; width: 60px; margin-right: 5px;} /* Green */
.board-buttons-blue {background-color: #2196F3; width: 280px; margin-right: 5px;} /* Blue */
.board-buttons-orange {background-color: #ff9800; width: 240px; margin-right: 5px;} /* Orange */
.board-buttons-red {background-color: #f44336; width: 200px; margin-right: 5px;} /* Red */ 
.board-buttons-dark {background-color: var(--dark); color: var(--off-white); width: 160px; margin-right: 5px;} /* Gray */ 

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.icon-font-size {
  transform: scale(1.8);
}

.rcorners2 {
  border-radius: 10px;
  border: 2px solid #73AD21;
  width: 36px;
  height: 36px;
}